import React from 'react';
import './NavMenu.css';

const NavMenu: React.FC = () => {
    return (
        <header className='nav-header'>
            <nav>
                <img src="/images/khora-logo-sm.png" alt="Khora Logo" style={{ width: '50px' }} />
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/contact">Contact</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default NavMenu;
