import NavMenu from "./components/NavMenu";
import './Layout.css';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout(props: LayoutProps) {
  return (
    <div>
      <NavMenu />
      <div className="main">
        {props.children}
      </div>
    </div>
  );
}
