import React from 'react';
import { ComingSoon } from './coming-soon/ComingSoon';

const AppRoutes = [
    {
        index: true,
        element: <ComingSoon />
    }
];

export default AppRoutes;
